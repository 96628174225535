import tw, { styled } from 'twin.macro'

export const Content = styled.article`
  ${tw`text-base lg:text-lg `}

  & > * {
    ${tw`mb-4`}
  }

  strong {
    ${tw`
  font-semibold
`}
  }

  h2 {
    ${tw`
  text-4xl
  font-medium
`}
  }
  h3 {
    ${tw`
  text-3xl
  font-medium
`}
  }
  h4 {
    ${tw`
  text-2xl
  font-medium
`}
  }
  h5 {
    ${tw`
  text-xl
  font-medium
`}
  }
  h6 {
    ${tw`
  text-lg
  font-medium
`}
  }

  a {
    ${tw`
  border-b-2
  border-orange-main
  hover:border-orange-light
`}
  }

  ul {
    ${tw`
    list-inside
  list-disc
`}
  }

  ol {
    ${tw`
    list-inside
  list-decimal
`}
  }
`
