import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Content } from './style'

const WhatYouSeeIsWhatYouGet = ({ content }) => {
  return (
    <div className={'sm-container mx-auto'}>
      <Content>{parse(content)}</Content>
    </div>
  )
}

export default WhatYouSeeIsWhatYouGet

WhatYouSeeIsWhatYouGet.propTypes = {
  content: PropTypes.string,
}
