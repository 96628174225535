import React from 'react'
import { StyledHeading } from './style'

const ArticleHeading = ({ text, fontSize, fontWeight, headingTag }) => {
  return (
    <div className={'sm-container mx-auto mb-4'}>
      <StyledHeading
        fontSize={fontSize}
        fontWeight={fontWeight}
        component={headingTag}
      >
        {text}
      </StyledHeading>
    </div>
  )
}

export default ArticleHeading

ArticleHeading.propTypes = {}
