import tw, { styled } from 'twin.macro'

import Heading from '../../../../common/heading'

export const StyledHeading = styled(Heading)(({ fontSize, fontWeight }) => [
  fontSize === 'lg' && tw`text-lg`,
  fontSize === 'xl' && tw`text-xl`,
  fontSize === '3xl' && tw`text-3xl`,
  fontWeight === 'normal' && tw`font-normal`,
  fontWeight === 'medium' && tw`font-medium`,
  fontWeight === 'semibold' && tw`font-semibold`,
  fontWeight === 'bold' && tw`font-bold`,
])
