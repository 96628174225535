import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../common/button'

const getVariant = (listItem, index) => {
  if (listItem.buttonVariant === 'primary') {
    return (
      <Button
        key={index}
        variant={'contained'}
        color={'blue'}
        isExternal={true}
        path={listItem.buttonLink.url}
        target={listItem.buttonLink.target}
      >
        {listItem.buttonLink.title}
      </Button>
    )
  } else
    return (
      <Button
        key={index}
        variant={'outlined'}
        isExternal={true}
        path={listItem.buttonLink.url}
        target={listItem.buttonLink.target}
      >
        {listItem.buttonLink.title}
      </Button>
    )
}

const ButtonList = ({ list }) => {
  return (
    <div className={'sm-container mx-auto flex flex-wrap gap-4 pb-4'}>
      {list.map((item, index) => getVariant(item, index))}
    </div>
  )
}

export default ButtonList

ButtonList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
}
