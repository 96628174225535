import React from 'react'
import PropTypes from 'prop-types'

import WhatYouSeeIsWhatYouGet from './what-you-see-is-what-you-get'
import ArticleMedia from './media'
import ArticleHeading from './heading'
import ButtonList from './button-list'

export const blocks = {
  whatYouSeeIsWhatYouGet: 'Wysiwyg',
  media: 'Media',
  heading: 'Heading',
  button: 'Button',
}

function getBlock(block, item) {
  switch (block) {
    case blocks.whatYouSeeIsWhatYouGet:
      return <WhatYouSeeIsWhatYouGet content={item.wysiwygContent} />
    case blocks.media:
      return (
        <ArticleMedia
          type={item.media.mediaSelect}
          image={item?.media?.image}
          video={item?.media?.video}
          file={item?.media?.file}
        />
      )
    case blocks.heading:
      return (
        <ArticleHeading
          fontSize={item.fontSize}
          fontWeight={item.fontWeight}
          headingTag={item.headingTag}
          text={item.headingText}
        />
      )
    case blocks.button:
      return <ButtonList list={item.buttonList} />
    default:
      return
  }
}

const getBlockString = (fieldGroupName) =>
  fieldGroupName.split('_').slice(-1).toString()

const ParsedContent = (list) => {
  return (
    <>
      {list.map((item, index) => (
        <div className={`relative`} key={index}>
          {getBlock(getBlockString(item.fieldGroupName), item)}
        </div>
      ))}
    </>
  )
}

const ArticleContent = ({ content }) => {
  return (
    <>
      {content && (
        <div className={`mt-5 mb-8 md:mb-20 md:mt-6`}>
          {ParsedContent(content)}
        </div>
      )}
    </>
  )
}

export default ArticleContent

ArticleContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
}
