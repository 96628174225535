import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import PlainLayout from '../components/plain-pages/plain-layout'
import SEO from '../components/seo'

const PlainTemplate = ({ data, location }) => {
  const { wpPage: page } = data
  const template = page.template.templatePlain

  return (
    <Layout template={page.template.templateName} location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      <PlainLayout
        title={page.title}
        content={page.template.templatePlain.content}
        cta={{
          title: template.title,
          button: template.button,
          quote: template.quote,
          image: template.image,
          name: template.name,
          jobTitle: template.jobTitle,
        }}
      />
    </Layout>
  )
}

export default PlainTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      template {
        templateName
        ... on WpPlainTemplate {
          templatePlain {
            content {
              ... on WpPlainTemplate_Templateplain_Content_Wysiwyg {
                wysiwygContent
                fieldGroupName
              }
              ... on WpPlainTemplate_Templateplain_Content_Media {
                fieldGroupName
                media {
                  mediaSelect
                  image {
                    altText
                    sourceUrl
                    publicUrl
                    caption
                  }
                  video {
                    sourceUrl
                    publicUrl
                    title
                    altText
                    caption
                  }
                  file {
                    publicUrl
                    caption
                  }
                }
              }
              ... on WpPlainTemplate_Templateplain_Content_Heading {
                fieldGroupName
                fontSize
                fontWeight
                headingTag
                headingText
              }
              ... on WpPlainTemplate_Templateplain_Content_Button {
                fieldGroupName
                buttonList {
                  buttonLink {
                    target
                    title
                    url
                  }
                  buttonVariant
                }
              }
            }
            button {
              target
              title
              url
            }
            image {
              altText
              publicUrl
            }
            jobTitle
            name
            quote
            title
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
    }
  }
`
