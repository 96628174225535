import React from 'react'
import { Arrow } from '../../../../../images/arrow'
import parse from 'html-react-parser'
import { Link } from 'gatsby'

const RenderMedia = (type, image, video, file) => {
  if (type === 'image') {
    return (
      <div className={'mb-5 md:mb-8 lg:mb-10'}>
        <img
          className={'w-full rounded-xl'}
          src={image.publicUrl}
          alt={image.altText ? image.altText : ''}
        />
        <div className={'flex gap-x-2 mt-3 lg:mt-5'}>
          {image.caption && (
            <>
              <Arrow direction={'up'} />
              {parse(image.caption)}
            </>
          )}
        </div>
      </div>
    )
  } else if (type === 'video') {
    return (
      <div className={'mb-5 md:mb-8 lg:mb-10'}>
        <video
          controls
          height={'100%'}
          width={'100%'}
          className={'mx-auto rounded-xl'}
        >
          <source src={video.publicUrl} type={'video/mp4'} />
          <source src={video.publicUrl} type={'video/webm'} />
        </video>
        <div className={'flex gap-x-2 mt-3 lg:mt-5'}>
          {video.caption && (
            <>
              <Arrow direction={'up'} />
              {parse(video.caption)}
            </>
          )}
        </div>
      </div>
    )
  } else if (type === 'file') {
    return (
      <div className={'mb-5 md:mb-8 lg:mb-10'}>
        <div className={'flex gap-x-2 mt-3 lg:mt-5'}>
          {file && (
            <a
              className={
                'border-b-2  border-orange-main   hover:border-orange-light'
              }
              href={file?.localFile?.publicURL}
              target={'_blank'}
            >
              {file.caption ? parse(file.caption) : 'Lenke'}
            </a>
          )}
        </div>
      </div>
    )
  }
}

const ArticleMedia = ({ type, image, video, file }) => {
  return (
    <div className={'lg-container mx-auto lg:px-0'}>
      {RenderMedia(type, image, video, file)}
    </div>
  )
}

export default ArticleMedia

ArticleMedia.propTypes = {}
