import React from 'react'
import PropTypes from 'prop-types'
import ArticleContent from '../articles/article/content'
import ProteketOnlineCallToAction from '../proteket-online/call-to-action'

const PlainLayout = ({ title, content, cta }) => {
  return (
    <div className={'w-screen bg-white h-full'}>
      <div
        className={
          'xl-container mx-auto pt-8 md:pt-20 lg:pt-32 pb-4 md:pb-8 lg:pb-16 flex flex-col'
        }
      >
        <h1
          className={
            'text-blue-dark text-4xl md:text-5xl lg:text-6xl font-medium text-center md:px-20'
          }
        >
          {title}
        </h1>
      </div>

      <ArticleContent content={content} />

      {cta && (
        <ProteketOnlineCallToAction
          title={cta.title}
          button={cta.button}
          image={cta.image}
          jobTitle={cta.jobTitle}
          name={cta.name}
          quote={cta.quote}
        />
      )}
    </div>
  )
}

export default PlainLayout

PlainLayout.propTypes = {
  title: PropTypes.string,
}
